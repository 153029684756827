import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import { Layers, Favorite, Search, Fullscreen } from '@material-ui/icons';

function Button(props: any) {
    const { totalWidth, width, height, mainColor } = useState(hive.state)

    const iconStyle = {
        width: height.get() * 0.05,
        height: height.get() * 0.05,
        marginTop: height.get() * 0.01
    }

    return (
        <div
            onClick={props.onClick}
            style={{
                border: '1px solid grey',
                position: "absolute",
                width: height.get() * 0.1,
                height: height.get() * 0.1,
                marginTop: height.get() * props.topRatio + "px",
                left: totalWidth.get() - (totalWidth.get() * 0.16),
                borderRadius: height.get() * 0.015 + "px",
                background: "#FFFFFF",
                opacity: "0.7",
                color: `#${mainColor.get()}`,
                textAlign: "center",
                fontSize: height.get() * 0.025,
                zIndex: 3,
                cursor: "pointer",
                userSelect: "none",
            }}>
            <div style={{
                width: "100%",
                height: height.get() * 0.05
            }}>
                {props.icon === "layer" &&
                    <Layers style={{
                        ...iconStyle
                    }}></Layers>
                }
                {props.icon === "favorite" &&
                    <Favorite style={{
                        ...iconStyle
                    }}></Favorite>
                }
                {props.icon === "search" &&
                    <Search style={{
                        ...iconStyle
                    }}></Search>
                }
                {props.icon === "full" &&
                    <Fullscreen style={{
                        ...iconStyle
                    }}></Fullscreen>
                }


            </div>
            <div style={{
            }}>
                {props.text}
            </div>
        </div>
    )
}

export default Button
